<template>
  <v-dialog v-model="isVisibleDeleteDialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">{{
        $t("delete_confirmation_message")
      }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeForm">{{
          $t("cancel")
        }}</v-btn>
        <v-btn color="blue darken-1" text @click="deleteItemConfirm(product)">{{
          $t("ok")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      product: "inventories/product",
    }),
    isVisibleDeleteDialog: {
      get() {
        return this.$store.state.inventories.isVisibleProductDeleteDialog;
      },
      set(value) {
        this.$store.commit("inventories/IS_VISIBLE_DELETE_DIALOG", value);
      },
    },
  },
  methods: {
    closeForm() {
      this.$store.dispatch("inventories/closeProductForm");
    },

    async deleteItemConfirm(product) {
      try {
        await this.$store.dispatch("inventories/removeProduct", product);
        this.closeForm();
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response.data.message);
      }
    },
  },
};
</script>
